.simplebar-form-style {
  height: 78vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-for-table-list {
  height: 68vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-for-three-column-layout {
  height: 75.1vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-view-page-tab {
  height: 69vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-three-column-layout-entry-item {
  height: 73.5vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-view-page-style-for-tab {
  height: 67vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-for-form-in-crm {
  height: 88vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
