//Dropdwon styles
.css-1rb6wtk-control {
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #b5b5b5 !important;
}

.css-1ti4bpj-control {
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #b5b5b5 !important;
}

.css-13cymwt-control {
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #b5b5b5 !important;
}

.css-t3ipsp-control:hover {
  border-color: #b5b5b5 !important;
}

.css-t3ipsp-control {
  border-color: #b5b5b5 !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
}

.css-evravd-control {
  border-radius: 0px !important;
}

.css-1e89cqw-control {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  border-color: #b5b5b5 !important;
  box-shadow: none !important;
}

.css-1e89cqw-control:hover {
  border-color: #b5b5b5 !important;
}

.css-1xc3v61-indicatorContainer {
  padding: 3px !important;
}

.css-1hb7zxy-indicatorContainer {
  padding: 3px !important;
}

.css-15lsz6c-indicatorContainer {
  padding: 3px !important;
}

.css-52g70f-control {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.css-1uby7e8-control {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  border-color: #b5b5b5 !important;
  box-shadow: none !important;
}

.css-rq5zit-control {
  border-color: #b5b5b5 !important;
  box-shadow: none !important;
}

.drop-down-menu:hover {
  background-color: #edf3f8 !important;
}

//Button styles
.list-add-button {
  padding: 2.8125px 6px !important;
}

.btn {
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

//Random styles for Form
.form-heading {
  margin-bottom: 0 !important;
  display: flex !important;
  align-items: center;
  font-size: 18px !important;
  text-transform: uppercase;
}

form .form-label {
  font-size: 12px;
  font-weight: 500 !important;
  color: black;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.simplebar-style {
  height: 79.8vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-for-coa {
  height: 83vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

//Alert styles
.alert {
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

//Sidebar style
.navbar-vertical .navbar-nav .nav-item .nav-link {
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.settings-item .name {
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.notification {
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.heading-style {
  font-size: 18px !important;
  margin: 0px !important;
  text-transform: uppercase !important;
  display: flex;
  align-items: baseline;
}

// Total component styles
.total-component-amount-style {
  font-size: 15px !important;
  color: black !important;
  font-weight: 500 !important;
}

.total-component-word-style {
  font-size: 14px !important;
  color: black !important;
  font-weight: 500 !important;
}

// Toast styles
.warnings {
  font-size: 11px !important;
  width: auto !important;
  min-width: 500px !important;

  .Toastify__progress-bar-theme--light {
    background: red !important;
  }
}

.success {
  font-size: 11px !important;
  width: auto !important;
  min-width: 500px !important;

  .Toastify__progress-bar-theme--light {
    background: rgb(0, 255, 85) !important;
  }
}

//Common footer style
.footer {
  position: sticky !important;
  z-index: 5 !important;
  background-color: white !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 5px !important;
}

//Dropdown add button style
.select-item-dropdown-styles {
  padding-top: 2px !important;
  padding-bottom: 5px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;

  .select-item-add-button {
    width: 100% !important;
    padding: 4px !important;
    border: 1px solid rgb(218, 215, 215);
    background-color: white !important;
    color: black !important;
    font-size: 11px !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
}

//Modal Form heading style
.modal-form-heading {
  text-transform: uppercase !important;
  font-size: 17px !important;
  color: black !important;
  margin: 0px !important;
}

.import-modal-footer-alignment {
  justify-content: flex-start !important;
}

//Sidebar style
.active-style {
  background-color: rgb(8, 17, 35) !important;
}

.active-style-with-children {
  background-color: rgb(30, 42, 65) !important;
}

.hover-style:hover {
  color: skyblue !important;
  font-size: 14px;
  transition: all 250ms ease-out;
}

//User Profile style
.user-profile-tab-style {
  .nav-tabs .nav-link {
    font-size: 12px !important;
  }
}

//OffCanvas Heading

.offcanvas-heading-style {
  text-transform: uppercase !important;
  font-size: 16px !important;
  color: black !important;
}

.disabled-input {
  caret-color: transparent;
  background-color: #f2f2f2;
}

.receive-payment-form-style {
  width: 100% !important;
  height: 80% !important;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.5));
  z-index: 1;
  position: absolute !important;
  top: 148px !important;
}

.customer-contact-styles {
  width: '18vw';
  overflow-x: 'hidden';
  overflow-y: 'auto';

  .card {
    height: 12vh !important;
    width: 15vw !important;
  }
}

.validation-text-style {
  font-size: 11px;
}

.modal-with-overlay .modal {
  position: relative !important;
  z-index: 1040 !important;
}

.modal-with-overlay::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.key-down-confirm-modal {
  .modal-content {
    width: 350px !important;
    border: 0px !important;
  }

  .header {
    border-radius: 0px !important;
  }
}

.position-sticky-bottom {
  position: sticky;
  bottom: 0;
}

// @media screen and (min-width: 1600px) and (max-width: 1680px) {
//   .customer-contact-styles {
//     height: 10vh !important;
//   }
// }

//For display resolution 1680 * 1050
@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .simplebar-style {
    height: 79.2vh !important;
  }
}

//For display resolution 1440 * 886
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .simplebar-style {
    height: 78.5vh !important;
  }
}

//For display resolution 1024 * 886
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .simplebar-style {
    height: 78.5vh !important;
  }
}

//For display resolution 320 * 425
@media screen and (min-width: 320px) and (max-width: 425px) {
  .simplebar-style {
    height: 78.5vh !important;
  }
}

.recent-activity-user-profile {
  .timeline-simple .timeline-item:not(:last-child)::before {
    content: "";
    position: absolute;
    border-left: 2px dashed var(--falcon-gray-200);
    height: calc(100% + 4rem);
    left: 8.3rem;
    top: 1.25rem;
  }
}

.password-validation-list {
  list-style: none;
  font-size: 11px;
}

.user-profile-vertical-tab {
  button {
    width: 265px;
    text-align: left;
    padding: 10px;
    padding-left: 20px;
  }

  .nav-link {
    color: #344050;
    font-size: 13px;
    margin-top: 8px;
    font-weight: 500;
  }

  .nav-link.active {
    background-color: #f0f4fe !important;
    color: #668fff !important;
    border-radius: 10px !important;

  }
}

.css-166ezxc-menu {
  overflow: hidden !important;
  overflow-x: scroll !important;
  max-height: 300px !important;
  overflow-y: scroll !important;

  ::-webkit-scrollbar {
    display: block !important;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #dddddd !important;
    border-radius: 10px !important;
    margin-left: 20px !important;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #eaf1fb !important;
    border-radius: 10px !important;
  }
}
.app-react-select__menu  {
  overflow: hidden !important;
  overflow-x: scroll !important;
  max-height: 300px !important;
  overflow-y: scroll !important;

  ::-webkit-scrollbar {
    display: block !important;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #dddddd !important;
    border-radius: 10px !important;
    margin-left: 20px !important;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #eaf1fb !important;
    border-radius: 10px !important;
  }
}