.custom-text-editor .ql-container {
    flex: auto;
  }
  
  .custom-text-editor .ql-container {


  }
  
  /* Snow Theme */
  .custom-text-editor .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;

  }
  .custom-text-editor{
     .ql-snow{
      .ql-editor{
           h1{
            font-size: 1.8rem !important;
           }
           h2{
            font-size: 1.3rem !important;
           }
           h3{
            font-size: 1rem !important;
           }
           }
      }
  }   h2,h1,h3{
    

  }