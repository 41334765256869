.messager-offcanvas-custom-style {
  width: 400px !important;
  z-index: 1100 !important;

  .messager-offcanvas-tabs-style {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    font-size: 13px;
  }

  .add-group-button {
    background-color: #58b5a0;
    color: white;
    height: 30px;
    padding: 0px;
    box-shadow: none !important;
    border-radius: 3px !important;
    width: 100px;
    position: fixed;
    right: 8px;
    z-index: 999;
    margin-top: 5px;
    /* top: 57px; */
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .tab-title {
    padding-left: 10px;
  }

  .chat-tab-custom-style {
    cursor: pointer;
    margin: 6px;
    margin-top: 18px;


    .user-name {
      font-weight: 500 !important;
    }

    .last-message {
      font-size: 11px !important;
    }



  }

  .role-name {
    font-size: 11px !important;

  }

  .active-green-symbol-style {
    position: absolute;
    top: 45px;
    left: 15px;
    font-size: 6px;
  }

  .active-green-symbol-style-rtl {
    position: absolute;
    top: 45px;
    right: 15px;
    font-size: 6px;
  }
}

.chat-window-small-screen-offcanvas-custom-style {
  top: auto !important;
  right: 25rem !important;
  max-width: 400px !important;
  height: 40rem !important;
}

.view-chat-modal {
  .chat-name {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: white;
  }

  .chat-role {
    font-size: 11px !important;
    color: white;
  }

  .offcanvas-header {
    .btn-close {
      color: white !important;
    }
  }
}

.chat-window-small-screen-offcanvas-custom-style-rtl {
  top: auto !important;
  left: 25rem !important;
  max-width: 400px !important;
  height: 40rem !important;
}

.header-active-green-symbol-style {
  position: absolute;
  top: 57px;
  left: 25px;
  font-size: 6px !important;
}

.header-active-green-symbol-style-rtl {
  position: absolute;
  top: 57px;
  right: 25px;
  font-size: 6px !important;
}

.chat-window-small-screen-body-custom-style {
  height: 30rem !important;
  margin: 0px !important;
  margin-top: 5px !important;
  padding: 0px !important;

  .simple-bar-custom-styles {
    height: 30rem !important;
    overflow-x: hidden !important;
    margin-bottom: 0px !important;
  }

  .message-content-right-custom-style {
    width: 70% !important;
    max-width: 70% !important;
    background-color: #eeeeee !important;
    padding: 10px !important;
    margin-left: 10px !important;
    border-radius: 10px !important;
    position: relative !important;
    padding-left: 1% !important;
    padding-right: 1% !important;

    .message-time {
      font-size: 10px;
      position: absolute;
      right: 5px;
      bottom: 0;
    }

    .message-right-side-content-arrow-styles {
      position: absolute;
      left: -10px;
      bottom: 0;
      border: 10px solid transparent;
      border-bottom-color: #eeeeee;
      border-right-color: transparent;
    }
  }

  .message-content-left-custom-style {
    width: 70% !important;
    max-width: 70% !important;
    background-color: #ffe5df !important;
    padding: 10px !important;
    margin-left: auto !important;
    border-radius: 10px !important;
    position: relative !important;
    margin-right: 15px !important;
    padding-left: 1% !important;
    padding-right: 1% !important;

    .message-time {
      font-size: 10px;
      position: absolute;
      right: 6px;
      bottom: 0;
    }

    .message-left-side-content-arrow-styles {
      position: absolute !important;
      right: -10px !important;
      bottom: 0 !important;
      border: 10px solid transparent !important;
      border-bottom-color: #ffe5df !important;
      border-right-color: transparent !important;
    }
  }

  .chat-attachment-custom-styles {
    position: relative !important;
    top: -54px !important;
    height: 70px !important;

    .attachment-simplebar-custom-styles {
      height: 55px;
      overflow-x: hidden;
    }
  }
}

.chat-window-big-screen-offcanvas-custom-style {
  height: 41rem;

  .big-screen-simplebar-custome-styles {
    height: 41rem;
    overflow-x: hidden;
    margin-bottom: 0px;

    .message-content-right-side-styles {
      width: 45%;
      max-width: 75%;
      background-color: #eeeeee;
      padding: 10px;
      margin-left: 10px;
      border-radius: 10px;
      position: relative;
      padding-left: 1%;
      padding-right: 1%;

      .chat-right-sided-arrow-style {
        position: absolute;
        left: -10px;
        bottom: 0;
        border: 10px solid transparent;
        border-bottom-color: #eeeeee;
        border-right-color: transparent;
      }
    }

    .message-content-left-side-styles {
      width: 45%;
      max-width: 75%;
      background-color: #ffe5df;
      padding: 10px;
      margin-left: auto;
      border-radius: 10px;
      position: relative;
      margin-right: 15px;
      padding-left: 1%;
      padding-right: 1%;

      .chat-left-sided-arrow-style {
        position: absolute;
        right: -10px;
        bottom: 0;
        border: 10px solid transparent;
        border-bottom-color: #ffe5df;
        border-right-color: transparent;
      }
    }
  }

  .attachment-big-screen-simplebar-custom-styles {
    position: relative;
    top: -58px;
    height: 70px;
  }
}

.group-info-users-active-style {
  position: absolute;
  top: 5px;
  left: 15px;
  font-size: 6px !important;
}